<template>
  <div style="text-align: left;">
    <div class="h1">E查工程会员服务协议</div>
    <div class="p fw">欢迎您使用E查工程的服务！</div>
    <div class="p fw">为使用E查工程的服务，您应当阅读并遵守《E查工程会员服务协议》（以下简称“本协议”）。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制的条款，对用户权利进行限制的条款，约定争议解决方式和司法管理的条款等，以及开通或者使用某项服务的单独协议或规则。限制、免责条款或者其他涉及您重大权益的条款可能以加粗、加下划线等形式提示您重点注意。</div>
    <div class="p fw">除非您已充分阅读、完全理解并接受本协议所有条款，否则您无权使用本协议服务。您点击“同意”或“勾选”，或者以其他任何明示或者默示方式表示接受本协议的，均视为您已阅读并同意签署本协议。本协议即在您与E查工程之间产生法律效力，成为对双方均具有约束力的法律文件。</div>
    <div class="h4">一、协议的范围</div>
    <div class="p">1.1 本协议是用户与E查工程之间关于其使用E查工程的服务所订立的协议。“E查工程”是指易信或其相关服务可能存在的运营关联单位。“用户”是指E查工程的服务的使用人，在本协议中更多地称为“您”。</div>
    <div class="p">1.2 会员的服务是指E查工程向用户提供的，包括但不限于信息查询、报告下载、支付、广告等产品及服务，具体服务以E查工程实际提供的为准（以下简称“本服务”）。</div>
    <div class="h4">二、账号与密码安全</div>
    <div class="p">2.1 您在使用本服务时需要注册一个帐号。关于您使用帐号的具体规则，请仔细阅读并遵守相关单独协议（《E查工程用户注册协议》）。</div>
    <div class="p">2.2 E查工程特别提醒您应妥善保管您的帐号和密码。当您使用完毕后，应安全退出。维护账号及密码的安全是您的责任，<span class="fw">因您保管不善可能导致遭受被盗号或密码失窃，责任由您自行承担。</span></div>
    <div class="h4">三、【用户个人信息保护】</div>
    <div class="p">3.1 您在注册帐号或使用本服务的过程中，可能需要填写一些必要的信息。若国家法律法规有特殊规定的，您需要填写真实的身份信息。若您填写的信息不完整，则可能无法使用本服务或在使用过程中受到限制。</div>
    <div class="p">3.2 一般情况下，您可根据相关产品规则浏览、修改自己提交的信息，但出于安全性和身份识别的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。</div>
    <div class="p">3.3 E查工程将尽可能运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。</div>
    <div class="p fw td">3.4 E查工程不会将您的个人信息转移或披露给任何第三方，除非：</div>
    <div class="p fw td">（1）相关法律法规或司法机关、行政机关要求。</div>
    <div class="p fw td">（2）为完成合并、分立、收购或资产转让而转移。</div>
    <div class="p fw td">（3）为提供您要求的服务所必需。</div>
    <div class="p">3.5 E查工程非常重视对未成年人个人信息的保护。<span class="fw">若您不具备完全民事行为能力，在使用E查工程的服务前，应事先取得您的监护人的同意。</span></div>
    <div class="h4">四、VIP会员服务条款</div>
    <div class="p">当您通过E查工程官方途径注册成为E查工程VIP会员（以下简称“VIP会员”）时，您在使用VIP会员服务的同时，须一并遵守本条的以下规定。为避免疑义，本条所指“VIP会员账号”或“账号”，包括关联的子账号。</div>
    <div class="p">4.1 成为VIP会员的方式</div>
    <div class="p">（1）在成为VIP会员之前，您应先根据真实、准确信息注册成为E查工程用户。当您的个人资料发生变化，应及时修改，否则由此造成的VIP会员权利不能全面有效行使的责任，由您自身承担，E查工程有权因此取消您VIP会员资格，无须退还VIP会员费或给予其他任何形式的赔偿或补偿。</div>
    <div class="p">（2）您应通过E查工程指定的购买渠道成为E查工程VIP会员，获取E查工程VIP服务。</div>
    <div class="p">（3）您应通过E查工程指定支付渠道支付E查工程VIP会员服务费用（包括网银支付或第三方支付等方式），您在支付VIP会员服务费用之前，须认可该项服务标明之价格，确认接受相关的服务条款及支付条款，严格按照支付流程操作，并在支付时注意及确保支付环境安全。<span class="fw">通过此种付费方式可能存在一定风险，包括但不限于不法分子利用您的E查工程账户或银行卡或第三方支付账户等进行违法活动，该等风险均会给您造成相应的经济损失，您应自行承担向侵权方追究侵权责任和追责不能的后果。</span></div>
    <div class="p">（4）VIP会员的服务价格以E查工程网站上标注的详细资费标价为准，E查工程对会员的服务有修改价格、升级服务、增减服务内容等权利。</div>
    <div class="p">（5）当您通过E查工程指定或明示认可的购买途径及支付途径并按照服务标准完成服务费用支付，且E查工程通过后台完成VIP会员服务激活时，您即成为E查工程VIP会员，E查工程会员服务即时生效并开始计算服务期限。</div>
    <div class="p">4.2 VIP会员服务的使用</div>
    <div class="p">（1）E查工程向VIP会员提供的全部服务，均仅限于该VIP会员在E查工程平台内使用，任何以恶意破解等不法手段将E查工程服务内容与E查工程平台分离的行为（例如恶意修改E查工程展示信息），均不属于E查工程服务。由此引起的一切法律后果由行为人负责，E查工程将依法追究行为人的法律责任。</div>
    <div class="p">（2）当您开始实际使用E查工程各单项服务时，视为您已同意该单项服务的服务条款以及E查工程在该单项服务中发出的各类公告的规定（如有）。</div>
    <div class="p">（3）您应自行妥善保管、使用、维护您在E查工程申请取得的账号、账号信息及密码，并采取必要和有效的保密措施，避免与第三人共享账号及相关服务信息。<span class="fw">非E查工程原因致使您账户密码泄漏以及因您保管、使用、维护不当造成损失的，E查工程无须承担与此有关的任何责任。</span></div>
    <div class="p">（4）VIP会员服务资费标准以E查工程网站上标注的详细资费标价为准，E查工程享有修改价格、升级服务、增减功能等权利。</div>
    <div class="p">（5）VIP会员服务具有固定的使用期限，您一旦成为VIP会员，即视为同意该服务使用期限的相关内容。</div>
    <div class="p">（6）成为VIP会员后，您有权停止或放弃接受E查工程服务，并有权注销E查工程用户账户，但E查工程无须向您退还已支付的任何费用。</div>
    <div class="p">4.3 VIP会员使用规范</div>
    <div class="p">（1）您知悉并同意：VIP会员账号同一时间同一账号仅可在1台移动通信设备上登录使用，超出上述范围使用的，将被强制下线。用户应当自行承担超范围使用而导致的任何损失，同时E查工程保留追究上述行为人法律责任的权利。</div>
    <div class="p">（2）您知悉并同意：您VIP会员账号内的行为（包括但不限于信息查询、信息发布等）均代表您本人行为，您应妥善保管您的账号及密码，通过E查工程官方渠道获取和使用VIP会员账号（亦包含通过该账号能够享受的E查工程会员服务，下同），并对该账号下的所有行为承担责任。</div>
    <div class="p fw">为保障您的账号安全，确保您的账号不被他人违规使用，您不应通过E查工程官方渠道之外的其他途径获取或使用E查工程VIP会员账号，或为他人通过其他途径获取或使用E查工程VIP会员账号提供条件。否则，E查工程有权视具体违规情节，采取强制修改密码直至封禁相关VIP会员账号等措施，暂停或终止为您提供服务，并无须退还任何费用。违规使用情形包括：</div>
    <div class="p">◆未经E查工程事先明示同意，转让、借用、出租、赠与或以其他方式，为他人使用您的VIP会员账号提供条件；</div>
    <div class="p">◆未经E查工程事先明示同意，通过爬虫工具、机器人软件等手段获取VIP会员服务或有关数据信息；</div>
    <div class="p">◆通过系统漏洞、黑色产业、滥用会员身份等不正当手段，侵犯E查工程或他人合法权益；</div>
    <div class="p">◆其他违反法律、法规或相关监管政策的行为，或违反本协议及其他服务规则的行为。</div>
    <div class="p">我们会基于如下标准判断您的账号是否存在前述违规使用情形：</div>
    <div class="p">◆同一账号近期登录使用设备数量是否异常；</div>
    <div class="p">◆同一账号单日查询次数是否异常；</div>
    <div class="p">◆是否存在其他足以被认定为违规使用的具体行为。</div>
    <div class="p">◆如您发现您的账号遭到未经授权的使用时，请您立即通知E查工程，我们将协助您找回。</div>
    <div class="h4">五、数据来源与责任限制</div>
    <div class="p">5.1 “E查工程”是由易信（ 厦门）信用服务技术有限公司与厦门信息集团大数据运营有限公司开发的一款企业相关数据等公开信息查询工具。根据用户指令，E查工程的搜索引擎系统会以非人工检索方式生成用户检索的已经依法公开的企业信息。</div>
    <div class="p">平台所登载的信息均来自于来源于厦门信息集团大数据运营有限公司运营的国家信用大数据创新中心等网站或机构，E查工程仅对相关网站依法公示的信息向用户如实展示，并不主动编辑或修改被所公示网站上的信息的内容或其表现形式。<span class="fw">受限于现有技术水平、各信息来源网站更新不同步等原因，对此类信息的展示，并不视为E查工程对其内容的真实性、准确性、完整性、时效性作出任何形式的确认或担保。请您在依据E查工程服务相关信息作出判断或决策前，自行进一步核实此类信息的完整或准确性，并自行承担使用后果。</span>同时，E查工程承诺将不断提升技术水平，逐步完善服务信息来源质量与更新频次，为您提供更高质量的服务。</div>
    <div class="p">5.2 <span class="fw">由于政府禁令、现行生效的适用法律或法规的变更、火灾、地震、瘟疫、战争、停电、通讯线路中断、电信部门技术调整、因政府管制而造成网站的暂时性关闭等其他任何影响网络正常运营的不可预见、不可避免、不可克服和不可控制的事件（“不可抗力事件”）；以及他人蓄意破坏，黑客攻击、计算机病毒，系统维护、系统升级，网络过载等技术问题（“技术事件”）而导致本网站不能访问而造成的本站所提供的信息及数据的延误、停滞或错误，E查工程无须承担责任。</span>同时，E查工程承诺将不断完善技术设施与条件，尽最大努力避免技术事件的发生，在不可抗力事件或技术事件发生时，在条件允许的前提下尽最大努力修复，以确保您继续正常使用，最大程度地避免您的损失。</div>
    <div class="p">5.3 请您知悉并同意，<span class="fw">由于E查工程服务的互联网服务性质，因此在服务的各个环节中不可避免地会受到相关不确定因素的影响（如电信运营商、网络质量、您使用的设备型号及系统、您的地理位置等），由于前述不确定因素造成您无法正常使用E查工程服务的，E查工程无须承担任何责任。</span>同时，E查工程承诺将在技术条件允许范围内尽最大努力为您解决，以确保您能够正常使用。</div>
    <div class="p">5.4 当您通过移动智能设备应用端使用E查工程服务时，请您知悉，我们针对不同品牌、不同型号的终端设备，开发了不同的移动应用程序版本，请您根据实际情况选择合适的版本进行下载及安装。<span class="fw">如果由于您E查工程之外的原因，导致您未能安装正确、对应的版本，我们无法保证该版本移动应用程序能够正常使用，并对因此给您造成的损失不予负责。</span></div>
    <div class="h4">六、【自备设备】</div>
    <div class="p">6.1 您应当理解，您使用本服务需自行准备与相关服务有关的终端设备（如电脑、移动终端和必要的网络接入设备等装置），并由您自行承担所需的费用（如电话费、上网费等费用）。</div>
    <div class="p">6.2 您理解并同意，您使用本服务时会耗用您的终端设备和带宽等资源。</div>
    <div class="h4">七、【服务费用】</div>
    <div class="p">7.1 E查工程的部分服务是以收费方式提供的，如您使用收费服务，请遵守相关的条款。</div>
    <div class="p">7.2 E查工程可能根据实际需要对收费服务的收费标准、方式进行修改和变更，E查工程也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，E查工程将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。</div>
    <div class="p">7.3 在E查工程降低收费服务的收费标准或者将收费服务改为免费服务提供时，E查工程保留不对原付费用户提供退费或者费用调整之权利。</div>
    <div class="h4">八、【基于软件提供服务】</div>
    <div class="p fs">E查工程依托“软件”向您提供服务，您还应遵守以下约定：</div>
    <div class="p">8.1为了改善用户体验、保障服务的安全性及产品功能的一致性等目的，E查工程可能会对软件进行更新。您应该将相关软件更新到最新版本，否则E查工程并不保证软件或服务能正常使用。</div>
    <div class="p">8.2除非E查工程书面许可，您不得从事下列任一行为：</div>
    <div class="p">（1）删除软件及其副本上关于著作权的信息。</div>
    <div class="p">（2）对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码。</div>
    <div class="p">（3）对E查工程拥有知识产权的内容进行出租、出借、复制、修改、链接、汇编、发表、出版、建立镜像站点等。</div>
    <div class="p">（4）对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、脚本或非经E查工程授权的第三方工具/服务接入软件和相关系统。</div>
    <div class="p">（5）通过修改或伪造软件运行中的指令、数据等任何方式，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的。</div>
    <div class="p">（6）通过非E查工程开发、授权的第三方软件、插件、脚本、系统、设备等任何方式，登录或使用E查工程软件及服务，或制作、发布、传播非E查工程开发、授权的用于登录或使用E查工程软件及服务的第三方软件、插件、脚本、系统、设备等。</div>
    <div class="h4">九、【知识产权声明】</div>
    <div class="p">9.1除另有声明外，E查工程提供本服务时所依托软件的著作权、专利权、商标权、商业秘密等知识产权及其他权利均归E查工程所有。</div>
    <div class="p">9.2上述及其他任何本服务包含的内容的知识产权均受到法律法规保护，未经E查工程、用户或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。</div>
    <div class="h4">十、【用户违法违规行为】</div>
    <div class="p">10.1如果您在使用本服务过程中违反了相关法律法规或本协议约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求E查工程给予协助。因此给您或者他人造成损害的，您应自行承担全部责任，E查工程不承担任何责任。</div>
    <div class="p fw">10.2如果您违反本协议约定，E查工程有权进行独立判断并采取相应措施，包括但不限于通过技术手段删除、屏蔽相关内容或断开链接等。同时，E查工程有权视用户的行为性质，采取包括但不限于暂停或终止向您提供服务，限制、中止、冻结或终止您对帐号的使用，追究法律责任等措施。</div>
    <div class="p">10.3您违反本协议约定，导致任何主体损失的，您应当独立承担责任；E查工程因此遭受的损失，您也应当一并赔偿。</div>
    <div class="h4">十一、【不可抗力及其他免责事由】</div>
    <div class="p fw">11.1您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务受到影响。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于政府禁令、现行生效的法律法规的变更及自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，E查工程将努力在第一时间与相关单位配合，争取及时进行处理，但是由此给您造成的损失E查工程在法律允许的范围内免责。</div>
    <div class="p fw">11.2在法律允许的范围内，E查工程对以下情形导致的服务中断或受阻不承担责任：</div>
    <div class="p fw">（1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏。</div>
    <div class="p fw">（2）用户或E查工程的电脑软件、系统、硬件和通信线路出现故障。</div>
    <div class="p fw">（3）用户操作不当或用户通过非E查工程授权的方式使用本服务。</div>
    <div class="p fw">（4）程序版本过时、设备的老化和/或其兼容性问题。</div>
    <div class="p fw">（5）电信部门技术调整或政府管制造成网站暂时性关闭。</div>
    <div class="p fw">（6）其他E查工程无法控制或合理预见的情形。</div>
    <div class="p fw">11.3 E查工程依据本协议约定获得处理违法违规内容的权利，该权利不构成E查工程的义务或承诺，E查工程不能保证及时发现违法行为或进行相应处理。</div>
    <div class="p fw">11.4 E查工程中所提供的信息资源均按照原样提供，不附加任何形式的保证，包括适合于特定目的或不侵犯知识产权的保证。此外，E查工程努力促进相关信息准确、全面和及时更新，但我们不对E查工程所提供信息材料的准确性、全面性和及时性向任何人作出保证，查询的结果请以官方网站公布为准。您同意：E查工程不对第三方（包括其他用户）提供的内容或信息负责，对任何人使用该信息产生的损失，E查工程及运营的公司不负任何法律上的责任。E查工程仅就用户提交的查询请求做识别应答，查询到的内容与被提交查询的主体是否相符由用户负责掌握和判断。E查工程没有义务对于识别结果本身的真实性进行分辨或核验，返回的数据结果，不构成E查工程对任何人至明示或暗示的观点或保证。</div>
    <div class="p fw">11.5 E查工程对有关用户在平台上交流的任何内容不承担任何责任，无论它们是否会因版权法引起诽谤、隐私、淫秽或其它方面的问题。E查工程保留删除包含被视为侮辱、诽谤、淫秽或其它不良内容的消息的权利。</div>
    <div class="p fw">11.6 E查工程到第三方网站的链接仅作为一种方便服务提供给您。如果使用这些链接，您将离开本平台。E查工程没有审查过任何第三方网站，对这些网站及其内容不进行控制，也不负任何责任。因此，E查工程对这些网站及其任何信息、软件或其他产品、材料、或因使用它们而可能获得的任何结果不予认可，也不作任何陈述。如果您决定访问任何与E查工程链接的第三方网站，其风险全部由您自己承担。</div>
    <div class="h4">十二、【协议的生效与变更】</div>
    <div class="p">12.1 您使用本服务即视为您已阅读本协议并同意本协议内容，自愿接受本协议的约束。</div>
    <div class="p">12.2 E查工程有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的协议条款。</div>
    <div class="p">12.3 本协议条款变更后，如果您继续使用E查工程提供的软件或服务，即视为您已接受变更后的协议。</div>
    <div class="h4">十三、【服务的变更、中断、终止】</div>
    <div class="p">13.1 您理解并同意，E查工程基于经营策略的调整，可能会对服务内容进行变更，也可能会中断、中止或终止服务。</div>
    <div class="p">13.2 在E查工程发生合并、分立、收购、资产转让时，E查工程可向第三方转让本服务下相关资产；E查工程也可在单方通知您后，将本协议下部分或全部服务及相应的权利义务转交由第三方运营或履行。具体受让主体以E查工程通知的为准。</div>
    <div class="p">13.3 如发生下列任何一种情形，E查工程有权不经通知而中断或终止向您提供服务，同时保留依法追究您的法律责任的权利，我们在E查工程中的记录有可能作为您违反法律和本协议的证据：</div>
    <div class="p">（1）根据法律法规规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明。</div>
    <div class="p">（2）您违反相关法律法规的规定或违反本协议的约定。</div>
    <div class="p">（3）按照法律法规规定，司法机关或主管部门的要求。</div>
    <div class="p">（4）出于安全的原因或其他必要的情形。</div>
    <div class="h4">十四、【法律适用与管辖】</div>
    <div class="p fw">14.1 本协议的生效、履行、解释及争议的解决均适用中华人民共和国法律。</div>
    <div class="p fw">14.2 您同意如因本协议引起的以及与本协议有关的纠纷以及因使用E查工程服务产生的争议应向厦门仲裁委员会申请仲裁。</div>
    <div class="h4">十五【其他】</div>
    <div class="p">15.1 如果您对本协议或本服务有意见或建议，可与E查工程客户服务部门联系，我们会给予您必要的帮助。（正文完）</div>

  </div>
</template>

<script>
</script>

<style scoped="scoped" lang="less">
.fw{
  font-weight: bold;
}
.h1{
  position: relative;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  padding: 46px;
  text-align: center;
}

.p {
  position: relative;
  display: block;
  font-size: 16px;
  color: #333333;
  padding: 0 15px 10px;
  text-indent: 2em;
  line-height: 1.5em;
}

.h5{
  position: relative;
  text-indent: 2em;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  padding: 0 15px 15px;
}

.text-right{
  position: relative;
  font-size: 16px;
  color: #999999;
  padding: 0 20px 24px;
}
.h4{
  position: relative;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  padding: 15px 15px;
}
.fs{
  position: relative;
  font-size: 16px;
  color: #333333;
  padding: 0 20px 20px;
  text-indent: 0em;
}
</style>
