<template>
  <div style="text-align: left;">
    <div class="h1 fw">企业征信授权书</div>
    <div class="p-none fw">本企业授权易信（厦门）信用服务技术有限公司：</div>
    <div class="p fw">一、查询、收集、整理和使用本企业以下信息：</div>
    <div class="p fw">（一）企业基本信息、企业金融信息、企业经营信息、企业负面信息、企业的董事、监事、高级管理人员与其履行职务相关的信息等企业向相关部门（单位）提供的和相关部门（单位）在管理工作中产生和收集的信息。</div>
    <div class="p fw">（二）国家法律、行政法规、部门规章、地方性法规未禁止征信机构采集的其他信息，但应遵循“最少、必要”原则。</div>
    <div class="p fw">二、从国家机关、政府部门、事业单位、金融机构、征信公司、行业协会、社会团体、关联企业、互联网信息平台经营者等单位依法采集上述信息。本企业授权上述单位提供上述信息。</div>
    <div class="p fw">三、查询、收集、整理和使用本企业在 E 查工程小程序“数据维护”中提供的所有数据及上传的资料。</div>
    <div class="p fw">四、向金融机构提供上述信息以及根据上述信息形成的分析、评价类信息。</div>
    <div class="p fw">五、本授权书经本企业授权人员勾选同意后生效。</div>
    <div class="p fw">上述授权的期限为：本授权书签署之日起至本企业终止本授权之日止。</div>
    <div class="p fw">本企业知悉并理解上述授权条款的内容，对授权的范围及内容已全部通晓并充分理解，授权行为的做出系本企业真实意思表示，本企业就易信（厦门）信用服务技术有限公司依本授权所为之查询、录入、使用、提供本企业上述信息行为无异议。本企业保证本企业向上述部门和单位提供和上传的信息真实、完整、合法、准确，且自愿承担因所上传的信息及资料错漏、违法等产生的任何法律责任及其他不良后果。</div>
    <div class="p text-right fw" >公司公章：</div>
    <div class="p text-right fw">法定代表人：</div>
    <div class="p text-right-100">____年____月____日</div>
  </div>
</template>

<script>
</script>

<style scoped="scoped" lang="less">
  .fw{
    font-weight: bold;
  }
  .h1{
    position: relative;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    padding: 46px;
    text-align: center;
  }

  .h5{
    position: relative;
    text-indent: 2em;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    padding: 0 15px 15px;
  }

  .p {
    position: relative;
    display: block;
    font-size: 16px;
    color: #333333;
    padding: 0 15px 10px;
    text-indent: 2em;
    line-height: 1.5em;
  }
  .p-none{
    position: relative;
    display: block;
    font-size: 18px;
    color: #333333;
    padding: 0 20px 20px;
    line-height: 1.5em;
  }
  .text-right{
    position: relative;
    font-size: 18px;
    color: #999999;
    padding: 0 20px 12px;
    text-align: right;
    right: 200px;
  }
  .h4{
    position: relative;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    padding: 0 20px 20px;
  }
  .fs{
    position: relative;
    font-size: 24px;
    color: #333333;
    padding: 0 30px 30px;
    text-indent: 0em;
  }
  .text-right-100{
    font-size: 18px;
    text-align: right;
    right: 100px;
  }
</style>
