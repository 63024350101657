<template>
  <div style="text-align: left;">
    <div class="h1">易信平台免责声明</div>
    <div class="p">特别注意：请使用本网站前仔细阅读以下条款，当你开始使用本网站即视为您已仔细阅读本条款，同意接受本服务条款的所有规范并愿受其拘束，本服务条款对您及易信（www.yigongcheng.com）均具有法律效力。</div>
    <div class="h5">数据来源</div>
    <div class="p">易信平台（www.yigongcheng.com）上的数据内容来源均为国家信用大数据创新中心（以下简称中心），在引用中心信息资源时，平台对有明确来源的信息注明了出处，版权归原网站所有，如果您对本平台信息资源版权的归属问题存有异议、认为数据不准确或不完整的，请您联系我们，我们会立即做出答复并及时解决。如果您认为本平台有侵犯您权益的行为，请通知我们，我们一定根据实际情况及时处理。</div>
    <div class="p">企业评分由大数据技术根据已公开的企业信息自动计算。该<span class="fw">评分的计算可能存在滞后性或不全面性。评分结果不必然代表被评企业的实际情况，评分结果仅供工程信易贷确定授信额度使用和参考，不做其他评价之用。</span>如被评企业有更新信息，欢迎提供给我们，以作修正。 本公司不对上述评分结果的使用承担任何法律责任。</div>
    <div class="h5">免责声明</div>
    <div class="p">任何人因使用本网站而可能遭致的意外及其造成的损失（包括因下载本网站可能链接的第三方网站内容而感染电脑病毒），我们对此概不负责，亦不承担任何法律责任。</div>
    <div class="p">本平台中所提供的信息资源均按照原样提供，不附加任何形式的保证，包括适合于特定目的或不侵犯知识产权的保证。此外，易信平台努力促进相关信息准确、全面和及时更新，<span class="fw">但我们不对平台所提供信息材料的准确性、全面性和及时性向任何人作出保证，对任何人使用该信息产生的损失，本平台及运营的公司不负任何法律上的责任。</span></div>
    <div class="p">易信（www.yigongcheng.com）对有关用户在平台上交流的任何内容不承担任何责任，无论它们是否会因版权法引起诽谤、隐私、淫秽或其它方面的问题。易信保留删除包含被视为侮辱、诽谤、淫秽或其它不良内容的消息的权利。</div>
    <div class="p">本平台到第三方网站的链接仅作为一种方便服务提供给您。如果使用这些链接，您将离开本平台。<span class="fw">易信平台没有审查过任何第三方网站，对这些网站及其内容不进行控制，也不负任何责任。因此，平台对这些网站及其任何信息、软件或其他产品、材料、或因使用它们而可能获得的任何结果不予认可，也不作任何陈述。如果您决定访问任何与本平台链接的第三方网站，其风险全部由您自己承担。</span></div>
    <div class="p">凡注明来源本网的所有内容，均为本网合法拥有版权或有权使用的作品，欢迎转载，注明出处。非本网内容均来自互联网，转载目的在于传递更多信息，并不代表本网赞同其观点和对其真实性负责。</div>
    <div class="h5">版权声明</div>
    <div class="p">本平台所有页面的版式、图片版权均为本平台所有，未经授权，不得用于除易信（www.yigongcheng.com）之外的任何站点。未经易信（www.yigongcheng.com）的明确书面许可，任何人不得复制或在非易信（www.yigongcheng.com）所属的服务器上做镜像。</div>
    <div class="p">易信（www.yigongcheng.com）授权您观看和下载本网站上的内容，但仅供您个人而非商业使用，而且，对于原始内容中所含的版权和其他所有权声明，您必须在其副本中予以保留。您不得以任何方式修改、复制、公开展示、公布或分发这些材料或者以其他方式把它们用于任何公开或商业目的。禁止以任何目的把这些材料用于其他任何网站或网络计算机环境。本平台上的内容受法律保护，任何未经授权的使用都可能构成对版权、商标和其他法律的违反。如果您违反任何条款，您使用本平台的授权将自动终止，同时您必须立即销毁任何已下载或打印好的材料。如果本平台的有关内容不小心侵犯了您的合法权益，请您及时联系我们，以便我们及时核实和改正。</div>
  </div>
</template>

<script>
</script>

<style scoped="scoped" lang="less">
.h1{
  position: relative;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  padding: 46px;
  text-align: center;
}

.h5{
  position: relative;
  text-indent: 2em;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  padding: 0 15px 15px;
}

.p {
  position: relative;
  display: block;
  font-size: 16px;
  color: #333333;
  padding: 0 15px 10px;
  text-indent: 2em;
  line-height: 1.5em;
}

.text-right{
  position: relative;
  font-size: 24px;
  color: #999999;
  padding: 0 30px 24px;
}
.fw{
  font-weight: bold;
}
</style>
