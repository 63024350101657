<template>
  <div style="text-align: left;">
    <div class="h1">E查工程会员登录协议</div>
    <div class="p">欢迎您访问E查工程！</div>
    <div class="p">
      本隐私政策是我们对用户个人信息收集、使用的规则。平台访问者（以下也称“用户”或“您”）的信息对我们非常重要，并且我们非常重视对您的隐私信息的保护
    </div>
    <div class="p">
      因此我们特对隐私信息的收集、使用和许可等作出如下规则:
    </div>
    <div class="h4">一、用户信息的收集范围</div>
    <div class="p">
      我们获取信息的主要目的在于向用户提供一个顺畅、高效的服务流程，并致力于不断完善和提升服务体验。我们可能会收集和使用的信息包括您主动提供给我们、我们主动向您收集以及第三方向我们提供的信息，具体包括：
    </div>
    <div class="p">
     （1）浏览信息：包括您的浏览记录、搜索记录等信息；
    </div>
    <div class="p">
     （2）注册信息：包括您在注册时设置的用户名、用户昵称，以及在注册时填写及后期补充的姓名、公司名称、证件号码、地址、邮箱、联系电话、传真、公司营业执照、个人身份证件等个人或公司信息。
    </div>
    <div class="p">
     （3）有关服务申请信息：包括申请人的姓名或名称、地址、联系人姓名、联系电话、收款账户等信息，以及上传的有关证件、证明材料的信息。
    </div>
    <div class="p">
     （4）支付信息：包括付款人、付款方式、付款金额、银行账号、微信、支付宝或其他支付工具等信息。
    </div>
    <div class="p">
     （5）设备信息：包括您的浏览器和计算机、手机等设备上的信息，如IP地址、浏览器的类型、语言、访问时间、软硬件特征等数据。当您下载或使用我们的客户端软件，或访问移动WAP站点时，我们会读取与您的位置和移动设备相关的信息，如设备型号、设备识别码、操作系统、分辨率、电信运营商等数据。
    </div>
    <div class="p">
     （6）其他在客服咨询、投诉或电话回访中获得的信息。
    </div>
    <div class="p">
     （7）其他您上传、拍照、填写的头像、身份证等个人信息，或者您直播过程展示的信息。
    </div>
    <div class="p">
     （8）在数据维护服务模块，您自主填报以及上传的有关信息及附件材料。
    </div>
    <div class="h4">二、用户信息的用途</div>
    <div class="p">
       为了向您提供服务，并不断提升我们的服务体验，我们将不可避免地使用您的信息，信息的使用方式和用途主要包括：
    </div>
    <div class="p">
      （1）向您提供您需要或可能感兴趣的产品或服务信息，包括但不限于您曾经查询过的有关主体的更新信息等；
    </div>
    <div class="p">
      （2）就您的要求向您提供咨询服务，或向您做出回应或与您沟通；
    </div>
    <div class="p">
      （3）根据您的申请，向您提供注册帐号，并授予您在E查工程平台申请有关服务的资格；
    </div>
    <div class="p">
      （4）收取、处理或退还款项；
    </div>
    <div class="p">
      （5）为评估和完善我们的服务，联系您进行调研；
    </div>
    <div class="p">
      （6）为评估和完善我们的服务，进行数据分析，如查询/申请行为或趋势分析、市场或顾客调查、财务分析；
    </div>
    <div class="p">
      （7）在事先获得您同意的情况下，向您指定的联系方式发送产品和服务信息；
    </div>
    <div class="p">
      （8）预防和追究各种违法、犯罪活动或违反我们政策规则的法律责任；
    </div>
    <div class="p">
      （9）经您许可的其他用途。
    </div>
    <div class="h4">三、用户信息的共享、披露与转让</div>
    <div class="p">
      1、用户的信息是我们业务的重要组成部分，我们不会出售或以其他方式共享您的个人信息，但如下情况除外：
    </div>
    <div class="p">（1）与我们的关联公司、合作方共享相关信息；</div>
    <div class="p">
      （2）与为我们提供短信验证服务、收付款服务或为我们发送信函等方面服务的合作伙伴共享相关信息；
    </div>
    <div class="p">（2）您应通过E查工程指定的购买渠道成为E查工程VIP会员，获取E查工程VIP服务。</div>
    <div class="p">
      （3）根据法律、法规及法律程序的规定；
    </div>
    <div class="p">
      （4）根据政府部门（如行政机构、司法机构）的要求；
    </div>
    <div class="p">（5）因司法程序或仲裁程序需要；</div>
    <div class="p">
      （6）事先获得您的许可。
    </div>
    <div class="p">
      2、在下列情况下，我们可能会共享的个人信息
    </div>
    <div class="p">
      （1）为实现程序化广告推送，我们可能会与广告合作伙伴共享去标识化或匿名化处理后的信息，我们可能与委托我们进行推广和广告投放的合作伙伴共享信息，但我们不会分享用于识别您个人身份的信息，例如您的姓名、身份证号等，仅会向这些合作伙伴提供难以识别您个人身份的用户画像标签及去标识化或匿名化后的统计类信息，以帮助其在不识别您个人身份的前提下提升广告有效触达率。
    </div>
    <div class="p">
      （2）为实现特定功能而与业务合作伙伴共享
    </div>
    <div class="p">当软件服务提供商、智能设备提供商或系统服务提供商与我们联合为您提供服务时，例如您需要使用地理位置功能时，为实现这一功能，我们可能会收集您的位置信息及相关设备信息（例如硬件型号、操作系统版本号、国际移动设备身份识别码（IMEI）、网络设备硬件地址（MAC））经过去标识化后并提供给前述提供商。您可以拒绝提供此类信息，但不影响您使用其他服务。</div>
    <div class="p">
      （3）帮助您参加营销推广活动
    </div>
    <div class="p">当您选择参加我们举办的有关营销活动时，根据活动需要您可提供姓名、通信地址、联系方式、银行账号等信息，以便第三方能及时向您提供奖品。当您选择参加我们举办的有关营销活动时，根据活动需要您可提供姓名、通信地址、联系方式、银行账号等信息。经过您的同意，我们会将上述信息与第三方共享，以便我们能委托第三方及时向您提供奖品。</div>
    <div class="p">
      3.对共享个人信息第三方主体的谨慎评估及责任约束
    </div>
    <div class="p">
      （1）经您同意，我们只会与第三方共享实现目的所必要的信息。如果第三方因业务需要，确需超出前述授权范围使用个人信息的，该第三方将需再次征求您的同意。
    </div>
    <div class="p">
      （2）对我们与之共享您个人信息的第三方，该些第三方会与我们签订保密协议。同时，我们会对其数据安全能力与环境进行评估并要求第三方以不低于本隐私政策所要求的保密和安全措施来处理该些信息。
    </div>
    <div class="p">
      4、为了给您提供更好、更优、更加个性化的服务，或共同为您提供服务，或为了预防互联网犯罪（如诈骗）的目的，我们的关联方、合作伙伴会依据法律的规定或与您的约定或征得您同意的前提下，向我们分享您的个人信息。
    </div>
    <div class="h4">四、Cookie 和同类技术</div>
    <div class="p">
     （一）Cookie
    </div>
    <div class="p">
      为确保平台正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，平台能够存储您的偏好或您感兴趣的商品或服务等数据。
    </div>
    <div class="p">
      您可根据自己的偏好管理或删除 Cookie。有关详情，请参见 AboutCookies.org。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的平台时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。
    </div>
    <div class="p">
     （二）平台信标和像素标签
    </div>
    <div class="p">
      除 Cookie 外，我们还会在平台上使用平台信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们平台内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。平台信标通常是一种嵌入到平台或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
    </div>
    <div class="h4">五、个人信息的访问、更正及删除</div>
    <div class="p">
      （一）访问您的个人信息
    </div>
    <div class="p">您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：</div>
    <div class="p">账户信息——如果您希望访问或编辑您在E查工程留存的个人信息，更改您的密码、邮箱、手机号码，或更改您的支付方式，您可以登录帐号并通过“账号管理”执行此类操作。</div>
    <div class="p">
      搜索信息——您可以登录账号并通过“授信记录”或者“查询记录”访问或清除您的搜索历史记录。
    </div>
    <div class="p">
      订单信息：您可以在E查工程中查阅或清除您的订单记录、交易记录等。
    </div>
    <div class="p">
      如果您无法通过上述路径访问个人信息，您可以随时通过E查工程客服与我们联系。我们会及时回复您的访问请求。
    </div>
    <div class="p">（二）更正或补充您的个人信息</div>
    <div class="p">
       当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过“（一）访问您的个人信息”中列明的方式提出更正或补充申请。
    </div>
    <div class="p">（三）删除您的个人信息</div>
    <div class="p">您可以通过“（一）访问您的个人信息”中列明的方式删除您的部分个人信息。</div>
    <div class="p">在以下情形中，您可以向我们提出删除个人信息的请求：</div>
    <div class="p">
      1、如果我们处理个人信息的行为违反法律法规；
    </div>
    <div class="p">
      2、如果我们收集、使用您的个人信息，却未征得您的明确同意；
    </div>
    <div class="p">
      3、如果我们处理个人信息的行为严重违反了与您的约定；
    </div>
    <div class="p">
      4、如果您不再使用我们的产品或服务，或您主动注销了账号；
    </div>
    <div class="p">
      5、如果我们永久不再为您提供产品或服务。
    </div>
    <div class="p">
      若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。
    </div>
    <div class="p">
      当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。
    </div>
    <div class="p">
     （四）注销账户
    </div>
    <div class="p">
      您可以联系我们（第九条方式）提交账户注销申请。
    </div>
    <div class="p">
      在您主动注销账户之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息或使其匿名化处理。
    </div>
    <div class="h4">六、约束信息系统自动决策</div>
    <div class="p">在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害E查工程商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。</div>
    <div class="h4">七、未成年人的保护</div>
    <div class="p">我们非常重视对未成年人个人信息的保护。我们不希望未成年人直接使用E查工程提供的产品或服务，如果您是未成年人，请与您监护人一起或在监护人的监督下使用本平台的产品或服务。</div>
    <div class="h4">八、《隐私政策》的更新</div>
    <div class="p">
       我们可能会不时更新本《隐私政策》，并在更新时在本平台上醒目位置发布公告，我们欢迎您随时会来查看本政策。
    </div>
    <div class="p">
      如果您对本隐私政策或E查工程的隐私保护措施以及您在使用中的问题有任何意见和建议请直接联系在线客服咨询。
    </div>
    <div class="h4">九、如何联系我们</div>
    <div class="p">
      您可以通过以下方式与我们联系，我们将在15天内回复您的请求：
    </div>
    <div class="p">
      1、如对本政策内容有任何疑问、意见或建议，您可通过E查工程客服在线客服与我们联系；
    </div>
    <div class="p">2、如发现个人信息可能被泄露，您可以通过电话{{ myCustomerServiceTel }} 或ly@e2winner.com企业邮箱投诉举报；</div>
    <div class="p">3、我们还设立了个人信息保护专职人员，您可以通过与其联系。</div>
    <div class="h4">十、争议解决机构的约定</div>
    <div class="p fw">如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向厦门仲裁委员会申请仲裁来寻求解决方案。</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'privacy-policy',
  data () {
    return {
      myCustomerServiceTel: ''
    }
  },
  computed: {
    ...mapGetters([
      'customerServiceTel'
    ]),
    customerServiceTelChange: function () {
      const vm = this
      if (vm.customerServiceTel) {
        return vm.customerServiceTel
      } else {
        return null
      }
    }
  },
  watch: {
    customerServiceTelChange: {
      immediate: true,
      handler: function (newValue, oldValue) {
        this.myCustomerServiceTel = newValue
      }
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.fw {
  font-weight: bold;
}
.h1 {
  position: relative;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  padding: 46px;
  text-align: center;
}

.h5{
  position: relative;
  text-indent: 2em;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  padding: 0 15px 15px;
}
.p {
  position: relative;
  display: block;
  font-size: 16px;
  color: #333333;
  padding: 0 15px 10px;
  text-indent: 2em;
  line-height: 1.5em;
}
.text-right {
  position: relative;
  font-size: 16px;
  color: #999999;
  padding: 0 15px 10px;
}
.h4 {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  padding: 0 15px 10px;
}
.fs {
  position: relative;
  font-size: 16px;
  color: #333333;
  padding: 0 15px 10px;
  text-indent: 0em;
}
</style>
