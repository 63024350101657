<template>
  <div class="container" style="min-height: 500px;margin-top: 90px;">
    <el-row  :gutter="30">
      <el-col :span="6">
        <div style="background-color: #fff;">
          <div @click="hangdleChange(item.label)" class="nav-content-li" :class="btnActive === item.label ? 'active' : ''" v-for="(item, index) of navList" :key="index">{{item.name}}</div>
        </div>
      </el-col>
      <el-col :span="18" style="background-color: #fff;margin-bottom: 30px;padding-bottom: 30px;">
        <service-agreement v-if="btnActive === 'vipServiceAgreement'"></service-agreement>
        <disclaimers v-if="btnActive === 'disclaimer'"></disclaimers>
        <privacy-policy v-if="btnActive === 'privacyPolicy'"></privacy-policy>
        <entrustment v-if="btnActive === 'creditAuthorization'"></entrustment>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ServiceAgreement from '@/components/agreement/service-agreement'
import PrivacyPolicy from '@/components/agreement/privacy-policy'
import disclaimers from '@/components/agreement/disclaimers'
import entrustment from '@/components/agreement/entrustment'
export default {
  components: { disclaimers, ServiceAgreement, PrivacyPolicy, entrustment },
  name: 'about',
  data () {
    return {
      btnActive: 'vipServiceAgreement',
      navList: [
        // {
        //   name: '关于我们',
        //   label: 'aboutMe'
        // },
        {
          name: '会员服务协议',
          label: 'vipServiceAgreement'
        },
        {
          name: '免责声明',
          label: 'disclaimer'
        },
        {
          name: '隐私政策',
          label: 'privacyPolicy'
        },
        {
          name: '企业征信授权书',
          label: 'creditAuthorization'
        }
      ]
    }
  },
  mounted () {
    const itemStr = sessionStorage.getItem('about-item')
    if (itemStr) {
      this.btnActive = itemStr
    }
  },
  methods: {
    hangdleChange (e) {
      this.btnActive = e
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.nav-content-li {
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  border-bottom:1px solid #ebebeb;
  text-align: left;
  cursor: pointer;
}
.nav-content-li:hover{
  color: #5D6FE9;
}
.nav-content-li.active{
  background-color: #5D6FE9;
  color: #fff;
}
</style>
